$datepicker__border-color: #e6e5e3 !default;
$datepicker__background-color: #0085cf !default;
$datepicker__background-color-outside: #ecebe8 !default;
$datepicker__selected-color: #00539b !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__text-color: #505050 !default;
$datepicker__triangle-arrow-color: #ffffff !default;

$datepicker__font-size: 1rem !default;
$datepicker__item-size: 2.857rem !default;
$datepicker__day-margin: 0 !default;
$datepicker__triangle-size: 8px !default;
$datepicker__margin: 0 !default;
$datepicker__navigation-size: .45rem !default;
