.settings {
  margin-bottom: 30px;

  .deselected {
    color: #989795 !important;
  }

  .item-header {
    font-style: italic;
  }

  .item-value {
    margin-bottom: 10px;
  }

  legend {
    font-weight: bold;
    color: inherit;
  }

  .radio-indent {
    margin-left: 26px;
  }

  fieldset fieldset .radio-indent {
    font-size: 0.9em;
    line-height: normal;
    margin-bottom: 10px;
  }

  fieldset fieldset {
    margin-top: 0;
  }

  :global(.react-datepicker-container) {
    width: 40%;

    :global(.form-group) {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0px;
    }
  }

  button {
    margin-top: 0;
  }

  button {
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.partform-checkbox-wrapper {
  line-height: normal;
  margin: 5px 0;
}

.device-questions-table {
  thead {
    td {
      text-align: center;
    }
  }
  tbody {
    th {
      white-space: nowrap;
    }
    td {
      text-align: center;
    }
  }
}
