.coordinator-courses-table {
  border-bottom: 1px solid #b2b2b2;

  thead {
    th {
      &.enrollments,
      &.exam-decision,
      &.payment {
        text-align: left;
      }
    }
  }

  .course-name {
    width: 20%;
  }

  .section-row {

    td {
      &.enrolled,
      &.taking-exam,
      &.payment {
        text-align: right;
      }
      &.actions {
        white-space: nowrap;
        a {
          > span {
            display: inline-block;
            width: 80%;
          }
        }
      }
    }
  }

  &.course-view {
    .course-row {
      .course-name {
        vertical-align: top;
        font-weight: bold;
      }
      .section-count {
      }
      .enrollments,
      .exam-decision,
      .payment {
        text-align: right;
      }
    }

    .section-row {
      td {
        border-top: 0;
        word-break: break-all;
        &.section-name {
          &:first-child {
            border-left: 1px solid #b2b2b2;
          }
        }
      }

      .section-name,
      .section-type,
      .teachers,
      .enrolled,
      .taking-exam,
      .enrollment-status,
      .payment,
      .actions {
        background-color: rgb(240, 240, 240);
      }
    }
  }
}

.coordinator-course-view-form {
  &.oversize,
  &.desktop,
  &.tablet {
    text-align:right;
  }

  &.mobile {
    text-align:left;
  }

  > div {
    margin-bottom:0;
    label {
      line-height:48px;
      vertical-align:top;
    }
    > div {
      display:inline-block;
      text-align:left;
      width:30%;
      margin-left:5px;
    }
  }
}

.teacher-courses-table {
  thead {
    tr {
      th {
        border-bottom-width: 0;
        &.enrollments,
        &.exam-decision,
        &.payment {
          text-align: left;
        }
      }
    }
  }

  tbody {
    th.section-name {
      font-weight: 400;
    }
    .section-row {
      .max-students,
      .enrolled,
      .taking-exam {
        text-align: right;
      }

      .joincode {
        white-space: nowrap;
        > span {
          width: 75px;
          display: inline-block;
        }
        > button {
          vertical-align: middle;
          padding: 0;
        }
      }

    }
  }

}
