.invoices {
	margin-top: 1.5em;
	margin-bottom: 5em;

	a {
		> :global(.cb-east) {
			font-size: 0.9em;
		}
	}

	.invoice-overdue {
		.status {
			color: #c13145;
		}
	}

	.invoice-refund {
		.status {
			color: rgb(0, 97, 0);
		}
	}

	.invoice-status-box,
	.invoice-status {
		h3 {
			font-family: 'Roboto', sans-serif;
			font-size: 1em;
			margin-bottom: 0;
		}
	}

	.invoice-status {
		margin-top: 1em;

		.status {
			font-family: 'Roboto Slab Bold', sans-serif;
			font-size: 1.5em;
			margin-top: 8px;
		}
	}

	.invoice-sidebar {
		h4,
		:global(.h4) {
			margin-top: 3em;

			&:first-of-type {
				margin-top: 1em;
			}
		}

		.invoice-sidebar-link {
			margin-top: 3em;
			display: block;
		}

		hr {
			margin-top: 3em;
			margin-bottom: 3em;
			border-top-color: #333;
		}
	}

	.oversize,
	.desktop {
		.invoice-sidebar {
			a,
			button {
				width: 100%;
			}
		}
		.invoice-table,
		.invoice-totals,
		.invoice-change-message {
			margin-right: 50px;
		}
	}

	.invoice-table {
		h3 {
			font-size: 1.1em;
			margin-top: 2.5em;
			margin-bottom: 1em;
		}

		table {
			border-bottom: 2px solid #333;
			margin-bottom: 12px;

			tr.highlight {
				background-color: #f1f1f1;
			}

			th,
			td {
				border-right: 0 !important;
				border-left: 0 !important;
				text-align: right;

				&:first-child {
					text-align: left;
				}
			}

			.description {
				width: 50%;
			}
			.quantity {
				width: 13%;
			}
			.price {
				width: 17%;
			}
			.amount {
				width: 20%;
			}
		}
	}

	.transfer-table {
		th,
		td {
			border-right: 0 !important;
			border-left: 0 !important;
			text-align: right;
			font-weight: 400;

			&:first-child {
				text-align: left;
			}
		}

		table {
			.description {
				width: 50%;
			}
			.quantity {
				width: 25%;
			}
			.price {
				width: 25%;
			}
		}
	}

	:global(.panel-body) {
		.invoice-download-link + .invoice-table {
			> h3 {
				margin-top: 0;
			}
		}
	}

	.invoice-total {
		width: 50%;
		float: right;
		font-weight: 700;
		padding: 8px 0 20px 5px;
		border-bottom: 1px solid #ccc;
		clear: both;

		div:first-child {
			float: left;
		}
		div:last-child {
			float: right;
			padding-right: 8px;
		}
	}

	.invoice-totals {
		margin-top: 3em;
		margin-bottom: 3em;

		&:after {
			content: ' ';
			display: table;
			clear: both;
		}

		.invoice-total {
			padding-top: 20px;

			&:first-child {
				border-top: 1px solid #333;
			}
			&:last-child {
				border-top: 1px solid #333;
				border-bottom: 1px solid #333;
				background-color: lighten(#fedb00, 40%);
			}
		}
	}

	.invoice-change-message {
		background: #f1f1f1;
		border: 1px solid #ccc;
		padding: 15px;
		margin-top: 3em;
	}

	.invoice-status-box {
		border: 1px solid #ccc;
		padding: 15px 15px 5px 15px;

		h3 {
			font-weight: 400;
		}

		.status {
			font-size: 1.1em;
			white-space: nowrap;
			font-weight: 700;
			margin-bottom: 10px;
		}

		a {
			display: block;
			line-height: 3em;

			&.mobile {
				line-height: normal;
				padding-bottom: 10px;
			}
		}
	}

	.invoice-download-link {
		margin-bottom: 1em;
		font-size: 0.875em !important;
		button {
			padding: 0;
			margin-right: 10px;

			i {
				font-size: 0.9em !important;
			}
		}
	}
	@media print {
		.invoice-table,
		.invoice-totals,
		.credits-and-totals-section {
			break-inside: avoid;
		}
	}
}
