.student-labels-content {
  h2 {
    font-size: 19px;
    padding-bottom: 15px;
  }
}

.sort-selection {
  &.mobile {
    .sort-order-form {
      text-align:left;
    }
  }
  &.tablet,
  &.desktop,
  &.oversize {
    text-align:right;
  }
}

.sort-order-form {
  display:inline-block;

  > div {
    display:inline-block;
    margin-bottom:0;

    > div {
      display:inline-block;
      text-align:left;
      margin:0 10px;
    }
  }

  button {
    margin-top:-2px;
  }
}
