.submitBox {
  background-color: #f0f0f0;
  padding: 10px;
  position: relative;

  p {
    font-size: small;
  }

  h4 {
    margin-bottom: 10px;
    font-size: 14px;
  }

  button:disabled {
    background-color: #fedb00;
    opacity: .6;
  }
}