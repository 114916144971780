@import '~@myap/ui-library/sass/queries-mixins.scss';

.nav-list-item {

  &.cb-multi {
    background: red;

  }

  @include desktop {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

:global(.cb-multi) {
  &.nav-list-item {
    > a > span {
      max-width: 80px !important;
    }
  }
}