#materialsList {
  @media only screen and (max-width: 767px) {
    margin-bottom: 50px;
  }

  h4 {
    margin: 20px 0 10px;
  }

  div div {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}

#step2 {
  margin-bottom: 10px;
}

#upperLeft {
  margin-bottom: 10px;
  h3 {
    font-size: large;
  }
}

.grouping {
  display: flex;
}

#packingShipmentAccordionContainer {
  #packingShipmentAccordion {
    border-bottom: 2px solid #505050;
  }

  a[data-toggle='collapse'],
  .panel-body {
    padding-left: 0;
  }

  .inline {
    margin-right: 72px;
  }
}

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff91;
}

.container {
  display: flex;
  justify-content: space-between;
}
