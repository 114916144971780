.locked-save-button {
	border-top: 1px solid #000;
	margin-top: 60px;
	padding-top: 30px;

	&:not(.multi-button.mobile) {
		button,
		a {
			padding-left: 60px;
			padding-right: 60px;
		}
	}
	&.multi-button {
		button,
		a {
			margin-right: 10px;
			margin-bottom: 10px;
		}
	}

	&.mobile {
		position: fixed;
		width: 100%;
		margin-top: 0;
		padding: 30px 15px 30px 15px;
		background: #fff;
		bottom: 0;
		left: 0;
		z-index: 999;

		&:not(.multi-button) {
			button,
			a {
				width: 100%;
			}
		}
	}
}

.max-width-inputs {
	:global(.form-group) {
		max-width: 75%;
	}
}

.input-sub {
	> div {
		margin-top: -12px;
		margin-bottom: 24px;
		padding-left: 24px;
	}
}
.header-legend {
	border-bottom: 2px solid #0077c8;
	padding-top: 30px;
	text-transform: uppercase;
	font-family: 'Roboto Slab Bold', sans-serif;
}
fieldset {
	p {
		margin-bottom: 1em;
	}
	hr {
		margin: 1em 0;
	}
	fieldset {
		margin-top: 1.5em;
		legend {
			margin-bottom: 0.5em;
		}
	}

	:global(legend.cb-base-font) {
		border-bottom: none;
		margin-bottom: 5px;
	}
}

.inline-radiogroup-flexwrap {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;
	margin-bottom: 0 !important;

	> div {
		flex-basis: 10%;
		flex-grow: 1;
		vertical-align: middle;
		padding-right: 7px;
	}
}

.inline-radiogroup {
	div {
		display: inline-block;
		.text-label-before {
			display: inline-block;
			margin: 6px 8px 0 6px;
			&.is-required {
				margin-right: 15px;
			}
		}
		.cb-error-msg {
			display: inline-block;
			margin-left: 8px;
			color: #c13145;
		}
	}
}

.radio-as-buttons {
	label {
		font-weight: 700;
	}

	&.mobile,
	&.tablet {
		:global(.radio) {
			display: inline-block;
			width: 50%;
			margin-bottom: 20px;

			label {
				padding-left: 0;
				width: 100%;
				border: 1px solid #999;
				padding: 20px 0;
				text-align: center;

				:global(.cb-span) {
					display: none;
				}

				&:hover,
				&:focus {
					background: #e4e4e4;
					text-decoration: underline;
				}
			}

			&:global(.selected) {
				label {
					background: #f0f0f0;
					text-decoration: underline;
					cursor: default;
				}
			}

			&:first-of-type label {
				border-radius: 2px 0 0 2px;
				border-right-width: 0;
			}
			&:last-of-type label {
				border-radius: 0 2px 2px 0;
			}
		}
	}
}
