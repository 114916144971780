.filter-container {
	background: rgba(0, 119, 200, 0.23);
	padding: 20px 0 15px 0;
	margin: 20px 0;

	.filter-components {
		display: flex;
		flex-wrap: wrap;

		.filter-checkbox {
			margin: 0.75em 1.75em 1.25em 0.5em;
			align-self: center;

			label {
				font-weight: 700 !important;
			}

			:global(.cb-span) {
				&:before {
					background: #ffffff;
				}
			}

			i {
				position: absolute;
				top: 4px;
				left: 23px;

				& + span {
					margin-left: 14px;
				}
			}

			:global(.alert-icon) {
				position: absolute;
				top: -1px;
				left: 1px;

				& + span {
					margin-left: 20px;
				}
			}
		}

		.filter-component {
			> div {
				margin-right: 10px;
				margin-bottom: 10px;
			}

			&.oversize {
				flex: 0 0 16.666667%;
				&:nth-child(6n) {
					> div {
						margin-right: 0;
					}
				}
				&:last-child {
					flex-grow: 0;
				}
			}

			&.desktop {
				flex: 0 1 25%;

				&:nth-child(4n) {
					> div {
						margin-right: 0;
					}
				}
			}

			&.tablet {
				flex: 0 0 33.33333%;

				&:nth-child(3n) {
					> div {
						margin-right: 0;
					}
				}
			}

			&.mobile {
				flex: 0 0 50%;

				&:nth-child(2n) {
					> div {
						margin-right: 0;
					}
				}
			}
		}
	}
}
.filter-tag {
	background: rgba(0, 119, 200, 1);
	font-size: 14px;
	color: #fff;
	padding: 3px 6px;
	margin: 5px 5px 5px 0;
	border-radius: 2px;
	display: inline-block;

	> button {
		background: #fff;
		border-radius: 50%;
		color: rgba(0, 119, 200, 1);
		border: 2px solid transparent;
		margin-left: 5px;
		padding: 2px;
		font-size: 12px;
		line-height: 10px;

		&:focus,
		&:hover {
			border-color: darken(rgba(0, 119, 200, 1), 10%);
		}
	}
}
.filter {
	position: relative;

	> button {
		width: 100%;
		position: relative;
		background: #fff;
		padding: 15px;
		border: none;
		text-align: left;
		font-weight: 700;
		font-family: 'Roboto Slab Bold', sans-serif;
		color: #000;
		border: 1px solid transparent;
		white-space: nowrap;

		&:focus,
		&:hover {
			text-decoration: underline;
			border-color: #000;
			outline-width: 0;
		}

		.menu-indicator {
			position: absolute;
			right: 10px;
			top: 20px;
		}
	}
}
.filter-open {
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.filter-dropdown {
	background: #fff;
	padding: 15px;
	position: absolute;
	left: 0;
	width: 125%;
	z-index: 999;
	box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
	max-height: 300px;
	overflow-y: auto;

	&.large {
		width: 325%;
	}

	.header {
		font-weight: bold;
		text-decoration: underline;
	}

	label {
		font-size: 14px;
		line-height: 18px;
		overflow-wrap: break-word;
		vertical-align: top;

		span {
			margin-right: 5px;
		}
	}

	> div > div {
		/* .checkbox */
		margin: 5px 0;
	}

	> .two-column {
		width: 50%;
		margin-top: 0 !important;
		display: inline-block;
		padding-right: 10px;
	}
}

.mobile,
.tablet {
	.large {
		width: 125%;
	}
	.two-column {
		width: 100%;
	}
}
