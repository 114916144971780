.tool-backdrop {
	opacity: 0.5;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	background-color: #000;
}

.tool-container {
	position: fixed;
	bottom: 0;
	z-index: 1000;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
	width: 400px;

	.tool-header {
		background: darken(#009cde, 7%);
		position: relative;

		h4 {
			font-family: 'roboto', sans-serif;
			font-weight: 700;
			color: #ffffff;
			line-height: 50px;

			&:not(:first-child) {
				margin-left: 50px;
				display: inline-block;
			}

			button {
				position: relative;
				text-align: left;
				padding-left: 15px;
				display: block;
				width: 100%;
				height: inherit;
				top: 0;
				font-size: 14pt;

				i {
					position: absolute;
					top: 5px;
					right: 5px;
					height: 40px;
					width: 40px;
					text-align: center;
					padding-right: 0;
				}
			}
		}

		button,
		.dropdown-trigger > a {
			color: #ffffff !important;
			height: 40px;
			background: none;
			border: none;
			font-weight: 700;
			position: absolute;
			width: 40px;
			padding: 0;
			top: 5px;

			i {
				width: inherit;
				height: inherit;
				display: inline-block;
				text-align: center;
				line-height: 40px;
			}

			&:hover,
			&:focus,
			&[aria-expanded='true']:not(button) {
				span {
					text-decoration: underline;
				}
				i {
					background: rgba(255, 255, 255, 0.2);
				}
			}

			&:not(:only-child):first-child {
				left: 5px;
			}

			&:not(:only-child):last-child {
				right: 5px;
			}
		}

		.dropdown-trigger > ul {
			top: 40px;
			left: 5px;
		}
	}

	.tool-body {
		overflow: hidden;
		background: #ffffff;
		font-size: 0.9em;
		line-height: 1.5em;
		position: relative;

		p {
			font-size: inherit;
		}

		:global(.btn-link) {
			padding: 0;

			+ button {
				margin-left: 15px;
			}
		}

		.search-input {
			label {
				font-weight: 400;
			}
		}

		.browse-items {
			padding: 15px;
			border-bottom: 1px solid #ccc;

			&:last-child {
				border-bottom: none;
			}

			h5 {
				font-family: 'Roboto', sans-serif;
				position: relative;

				a {
					color: #555;

					i {
						position: absolute;
						top: 4px;
						right: 0;
						color: #0077c8;
					}
				}
			}

			&.browse-item-main {
				h5 {
					a {
						color: #0077c8;
					}
				}

				&.disabled {
					h5 {
						color: #555;

						a {
							color: inherit;
							cursor: default;

							&:hover,
							&:focus {
								text-decoration: none;
							}

							i {
								color: inherit;
							}
						}
					}
				}
			}
		}

		.tool-body-inner {
			height: calc(100% - 75px);
			overflow-y: scroll;
			padding: 15px 0 0 0;

			.tool-body-item {
				padding-left: 15px;
				padding-right: 15px;
			}

			.tool-body-divider {
				border-bottom: 1px solid #ccc;
				padding: 0 15px 15px;
				margin-bottom: 0;

				&:not(p) {
					padding-bottom: 5px;
				}
			}

			.tool-body-accordion {
				border-bottom: 1px solid #ccc;
				padding: 0 15px;

				&:last-child {
					border-bottom: none;
				}

				.tool-body-accordion-header {
					font-size: 1.1em;
					padding: 15px 0 10px 0;

					> div {
						float: left;
					}

					> h5 {
						font-family: 'roboto', sans-serif;
						position: relative;
						float: right;
						width: 90%;
					}

					button {
						text-align: left;
						width: 100%;
						border: none;
						background: none;
						padding: 0;

						&:hover,
						&:focus {
							text-decoration: underline;
						}

						> i {
							position: absolute;
							right: 0;
							top: 5px;
						}
					}
				}

				&.sticky {
					.tool-body-accordion-header {
						position: fixed;
						background: #ffffff;
						z-index: 1;
						display: block;
						border-bottom: 1px solid #ccc;
						padding-left: 15px;
						margin: 0 -15px;

						label {
							display: none;
						}

						h5 button i {
							right: 15px;
						}
					}
				}
			}
		}

		.tool-buttons {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: #ffffff;
			padding: 15px 15px 0 15px;
			border-top: 1px solid #ccc;

			> div > div:only-child {
				width: 100% !important;
			}

			button {
				width: 100%;
				margin-bottom: 10px;
				padding-left: 5px;
				padding-right: 5px;
				white-space: normal;
			}
		}
	}
}
