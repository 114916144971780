.join-code-container {
	margin: 20px;
	display: inline-block;
	border: 1px solid #ccc;
	position: relative;
}

.join-code-url {
	padding: 10px;
	margin-top: 5px;
	border-top: 1px solid #ccc;
}
.copy-to-clipboard {
	position: absolute;
	bottom: 3px;
	right: -30px;
}
.join-code-buttons {
	margin-top: 35px;
	margin-bottom: 5px;
	width: 100%;
}

.print-version-page {
	width: 50%;
	margin: 8% auto;
	@media print {
		width: 75%;
		margin: 0.5in auto;
	}

	.print-course-name {
		@media print {
			font-size: 24pt;
		}
	}

	.print-teacher-name {
		@media print {
			font-size: 20pt;
		}
	}

	.join-code-container {
		display: block;
		margin: 20px 0;
		width: 100%;
		@media print {
			background: #f0f0f0 !important;
			border: 1px solid #ccc;
			padding: 0.5cm 0.5cm 0.25cm 0.5cm;
			display: inline-block;
			text-align: center;
			text-transform: uppercase;
			margin: 1.5cm 0;
		}
	}
	.join-code-wrap {
		font-size: 56pt;
		line-height: normal;

		div:first-of-type {
			font-size: 24pt;
			color: #767676;
			@media print {
				font-size: 34pt;
			}
		}
		@media print {
			font-size: 80pt !important;
		}
	}
}

.join-code-form {
	text-align: center;
	margin-bottom: 30px;

	> div > div {
		display: inline-block;
	}

	p {
		margin-top: 20px;
		text-align: left;
	}

	input {
		height: 70px;
		font-size: 1.5em;
		max-width: 230px;
		text-align: center;

		& + i {
			top: 28px !important;
		}
	}
}
