tbody {
	td {
		&.with-icon {
			padding: 0 !important;

			> .with-icon-container {
				padding: 10px 5px 10px 28px;
				position: relative;
				white-space: nowrap;

				> i {
					position: absolute;
					top: 14px;
					left: 5px;
					font-size: 1em;
				}
			}
		}

		&.numeric-column {
			text-align: right;
		}

		.exam-date-wrapper {
			white-space: nowrap;
			width: 180px;
			padding: 10px;

			&.with-time {
				width: 220px;
			}

			&.flexible {
				width: auto;
			}

			.exam-type {
				display: inline-block;
				width: 105px;
			}
			.exam-date {
				white-space: nowrap;
				display: inline-block;
				width: 50px;
			}
			.exam-time {
				display: inline-block;
			}
		}
	}

	th[scope='row'],
	th[scope='rowgroup'] {
		font-weight: 400;
	}

	.top-aggregate-row {
		> th,
		td {
			font-weight: 700;
			background: #eee;
			border-bottom: 2px solid #505050 !important;
		}
	}
}

thead th[data-colsort],
:global(.ReactVirtualized__Table__sortableHeaderColumn) {
	position: relative;
	padding: 0 !important;

	a,
	:global(.sortable-header) {
		color: white;
		text-decoration: none !important;
		display: block;
		padding: 11px 20px 12px 8px;

		&:hover,
		&:focus {
			color: inherit;
			text-decoration: underline !important;
		}

		:global(.cb-sort),
		:global(.cb-sort-asc),
		:global(.cb-sort-desc) {
			position: absolute;
			right: 5px;
			top: 14px;
		}
	}
}

thead th,
:global(.ReactVirtualized__Table__headerColumn) {
	[data-toggle='tooltip'] {
		display: inline-block;
		vertical-align: middle;
		padding-left: 4px;
		padding-top: 1px;
		float: none !important;

		i {
			font-size: 0.7rem !important;
			&:after {
				background-color: #fff !important;
				height: 1.2rem !important;
				width: 1.2rem !important;
			}
			&:before {
				color: #505050 !important;
				left: calc(0.55rem / 2) !important;
				top: calc(0.55rem / 2) !important;
			}
			&:hover {
				&:after {
					background-color: #0077c8;
				}
				&:before {
					color: #fff;
				}
			}
		}
	}
}
