.detailed-container {
  border: solid 1px #d9d9d9;
  display: inline-block;
  padding: 12px;
  margin-bottom: 24px;
  margin-right: 24px;
  width: 350px;
}

.pb-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.pb-footer-left {
  align-items: center;
  display: flex;
}

.testDay-contianer {
  margin-bottom: 24px;
}