#shipmentAccordionContainer {
    a[data-toggle="collapse"] {
        padding-left: 0;
        font-size: 18px;
    }
    td,th {
        width: 80%;
    }
    
}

#rightShipment {
    .rightShipmentColumn {
      margin-top: 20px;
        h2 {
            margin: 0 0 16px 0;
            padding-bottom: 7px;
            border-bottom: 1px solid #d9d9d9;
        }

        &:not(:first-child)>h2 {
            margin-top: 70px;
        }
    }
}