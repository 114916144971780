.section-details-table {
  .student-name {
    padding-left: 32px;
  }
  .taking-exam,
  .exam-date,
  .actions {
    white-space: nowrap;
    a {
      > span {
        display: inline-block;
        width: 80%;
      }
    }
  }
}