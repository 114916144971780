$border_color: #d9d9d9;

.multiselect-column {
	label {
		> span {
			margin: 0 !important;
		}
	}

	&:global(.ReactVirtualized__Table__headerColumn) {
		label {
			> span {
				&::before {
					color: white;
				}
			}
		}
	}
}

.student-name-column {
	padding: 0;
	border-top: none;
	overflow: visible !important;

	&.merged {
		background: #fff !important;
	}

	> span {
		display: block;
		height: inherit;
		width: 100%;
		border-top: 1px solid #fff;
	}

	> div {
		padding: 11px 10px;
		border-top: 1px solid $border-color;
		overflow: hidden;
		text-overflow: inherit;

		&.first-of-many {
			overflow: visible;
			white-space: normal;
			word-break: break-all;
		}
	}
}

.taking-exam-column {
	position: relative;
	white-space: nowrap;

	> div > i {
		position: absolute;
		top: 14px;
	}

	&.level-two {
		> div > div {
			padding-left: 25px;
		}
	}
}
.test-date-column {
	padding: 0;

	a {
		> i {
			position: absolute;
			right: 5px;
			top: 5px;
		}
	}
}

tbody {
	th.name-column {
		vertical-align: top !important;
	}
	td.taking-exam-column,
	td.fees-column,
	td.actions-column {
		white-space: nowrap;
		a {
			> span {
				display: inline-block;
				width: 80%;
			}
		}
	}
	td.course-column {
		border-left: 1px solid #b2b2b2 !important;
	}
	td.test-date-column {
		width: 200px;

		> .test-date-container {
			height: 42px;
			width: inherit;

			ul {
				right: -5px !important;
				width: 215px;
			}

			a {
				> i {
					position: absolute;
					right: 5px;
					top: 5px;
				}
			}
		}
	}
}
